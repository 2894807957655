import React, { useEffect, useState } from "react";
// @mui
import {
  Card,
  Box,
  CircularProgress,
  Stack,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import FilterListIcon from "@mui/icons-material/FilterList";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"; // Import your desired icon
import GetRequest from "../components/apiConnections/getRequest";

function VerticalChartBarForCVE() {
  const [value1, setValue] = useState("false");
  const [loader, setShowLoader] = useState(false);
  const [cveData, setcveData] = useState([]);
  const [totalCVE, setTotalCVE] = useState(0);

  const handleChange = (event) => {
    setcveData([])
    setValue(event.target.value);
  };
  useEffect(() => {
    const totalCVECount = cveData.reduce((total, item) => total + item.cveCount, 0);
    setTotalCVE(totalCVECount);
  }, [cveData]);

  useEffect(() => {
    getIssueVerticalcveData();
  }, [value1]);

  const getIssueVerticalcveData = async () => {
    setShowLoader(true);
    try {

      await GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CVE_VERTICAL_BAR_CHART}?criticalCVES=${value1}`
      )
        .then((response) => {
          if (value1 !== "false") {
            // Sorting logic: Critical -> High -> Medium -> Low
            const severityOrder = {
              critical: 1,
              high: 2,
              medium: 3,
              low: 4,
            };
            const sortedData = [...response?.data?.data].sort((a, b) => {
              const severityA = severityOrder[a.cveName?.Severity?.trim().toLowerCase()] || 5;
              const severityB = severityOrder[b.cveName?.Severity?.trim().toLowerCase()] || 5;
              return severityA - severityB;
            });
            setcveData(sortedData);
          } else {
            const data = response?.data?.data.filter((item) => item?.cveName?.Issue !== null)
            setcveData(data)
          }

          setShowLoader(false);
        })
        .catch((errDashboard) => {
          setShowLoader(false);
        });
    } catch (error) {
      setShowLoader(false);
    }
  };
  const colors = ['#2C226D', '#2C226D', '#2C226D', '#2C226D', '#2C226D'];
  const colors2 = ['#63A7E3', '#439B46', '#CA8B3B', '#BFA73E', '#7D4DD0']
  const colorsCritical = ['#2C226D', '#2C226D', '#2C226D', '#2C226D', '#2C226D'];
  const colorsCritical2 = ['#FF1B16', '#FFBC11', '#FF015E', '#DE22FF', '#FF5404']

  return (
    <>
      <Card sx={{ mb: 1 }}>
        <div className="top-heading-dashboard">
          <div className="filter-select-dropdown">
            <h2>CVE Vertical Bar Chart </h2>
            <FormControl className="width-set-formcontrol">
              <InputLabel id="demo-simple-select-label">Filter</InputLabel>
              <Select
                value={value1}
                onChange={handleChange}
                // label="Filter"
                IconComponent={ArrowDropDownIcon}
              >
                <MenuItem value="false">Top CVE</MenuItem>
                <MenuItem value="true">Top Critical CVE</MenuItem>
              </Select>
              <FilterListIcon className="filter-icon" />
            </FormControl>
          </div>
        </div>
        <p className="top-issues-vertical-heading">
          {value1 === "false" ? "Top CVE" : "Top Critical CVE"}</p>
        {!loader && cveData ?
          <>
            {(cveData && cveData?.length > 0) ?

              cveData?.slice(0, 5).map((key, i) => (
                <Stack
                  sx={{ width: "100%", mt: 2 }}
                  spacing={2}
                  className="vertical-chart-linearprocess"
                >

                  <div className="cover-vertical-chart">
                    <p className="linearprocess-peragraph">
                      {key?.cveName?.Issue}
                      {totalCVE && <span>{((Math.abs(Number(key?.cveCount)) * 100) / totalCVE)?.toFixed(2)}%</span>}
                    </p>
                    {value1 === "false" ? <LinearProgress
                      sx={{
                        backgroundColor: colors[i],
                        "& .MuiLinearProgress-bar": {
                          backgroundColor: colors2[i],
                        },
                      }}
                      variant="determinate"
                      className="vertical-progresschart"
                      value={key?.cveCount}
                    /> :
                      <LinearProgress
                        sx={{
                          backgroundColor: colorsCritical[i],
                          "& .MuiLinearProgress-bar": {
                            backgroundColor: colorsCritical2[i],
                          },
                        }}
                        variant="determinate"
                        className="vertical-progresschart"
                        value={key?.cveCount}
                      />
                    }
                  </div>
                </Stack>
              ))
              :
              // When no data is Available
              (
                <Stack
                  sx={{ width: "100%", mt: 2 }}
                  spacing={2}
                  className="vertical-chart-linearprocess"
                >
                  <div className="cover-vertical-chart">
                    <p className="linearprocess-peragraph">
                      No Data Available
                    </p>
                  </div>
                </Stack>
              )
            }
          </> :
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", marginTop: "23%" }}>
            <CircularProgress className="loader-custom-class-new" />
          </Box>
        }
      </Card>
    </>
  );
}

export default VerticalChartBarForCVE;
