import react, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import PostRequest from '../apiConnections/postRequest';
import Loader from '../../Loader';
import CrossSelect from '../../assets/cross-select.svg';

import {
    Grid, FormControl, InputLabel, Select, MenuItem
} from "@mui/material";

export default function ShowAssessmentModal({ handleClose, open, data, onSubmit, handleSubmit }) {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [theme, setTheme] = useState(localStorage.getItem('theme'));
    const [showLoader, setShowLoader] = useState(false);
    const [vendorData, setVendorsData] = useState([])
    const [vendor, setVendor] = useState('');
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        // width: 600,
        // bgcolor: 'background.paper',
        // boxShadow: 24,
        textAlign: 'center',
        borderRadius: '10px',
        p: 4,
    };
    useEffect(() => {
        setInterval(() => {
            const themeName = localStorage.getItem('theme') !== null ? localStorage.getItem('theme') : 'Dark';
            setTheme(themeName);
        }, 2000)
        getVendors()
    }, [])
    const getVendors = async () => {
        setShowLoader(true);

        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                {},
                "refreshToken"
            );
            if (refreshToken) {
                await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${"get-vendor-domains"}`,
                )
                    .then((resVendors) => {
                        let arrResponse = resVendors?.data;
                        setVendorsData(arrResponse);
                        // console.log(resVendors)
                        // console.log(vendorData)
                        setShowLoader(false);
                    })
                    .catch((errVendor) => {
                        enqueueSnackbar(errVendor.data?.message, { variant: 'error' });
                        setShowLoader(false);
                    })
            }
        }
        catch (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
            setShowLoader(false);
        }
    }
    const handleChange = (event) => {
        setVendor(event.target.value)
      if(event.target.value){
        navigate(`/dashboard/vendor-information/${event.target.value}`,{state: `../assessment/`})
      }
      };
    //   console.log("vendorData" , vendorData)
    return (
        <div>
            <Loader show={showLoader} />
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className={(theme == 'Dark') ? 'dark-modal modal-popup' : 'light-modal modal-popup'}
            >
                <Box sx={style} className="select-vendor-popup">
                       <div className='select-assessment-heading'>
                        <Typography>
                            Select Vendor
                        </Typography>
                        <img src={CrossSelect} alt='icon' onClick={handleClose}/>
                        </div>
                    <div className='padding-form-assessment'>
                        <p>Please add a vendor before sending the assessment.</p>
                         <FormControl size="small" className='select-dropdown-assessment'>
                            <InputLabel id="demo-select-small-label">Vendor</InputLabel>
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={vendor}
                                label="Vendor"
                                onChange={handleChange}
                            >
                                {Array.isArray(vendorData) ? vendorData?.map((data, i) => {
                                    return <MenuItem key={i} value={data?.requestUrl}>{data?.requestUrl}</MenuItem>
                                }) : null}
                            </Select>
                         </FormControl>
                          <Grid item xs={12} md={12} lg={12} className="center-vendor">
                           <Button variant="contained" className="cancel-popups-btn" onClick={handleClose}>
                            Done
                            </Button>
                          </Grid>
                        </div>
                    </Box>
              </Modal>
        </div>
    );
}