import React, { useEffect, useState } from "react";
// @mui
import {
  Card,
  Box,
  CircularProgress,
  Stack,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import FilterListIcon from "@mui/icons-material/FilterList";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"; // Import your desired icon
import GetRequest from "../components/apiConnections/getRequest";

function VerticalChartBarForIssue() {
  const [value, setValue] = useState("false");
  const [loader, setShowLoader] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [totalIssue, setTotalIssue] = useState(0);
  const handleChange = (event) => {
    setChartData([])
    setValue(event.target.value);
  };
  useEffect(() => {
    const totalIssueCount = chartData.reduce((total, item) => total + item.issueCount, 0);
    setTotalIssue(totalIssueCount);
  }, [chartData]);

  useEffect(() => {
    getIssueVerticalChartData();
  }, [value]);

  const getIssueVerticalChartData = async () => {
    setShowLoader(true);
    try {
      await GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ISSUE_VERTICAL_BAR_CHART}?criticalIssues=${value}`
      )
        .then((response) => {
          if (value !== "false") {
            // Sorting logic: Critical -> High -> Medium -> Low
            const severityOrder = {
              critical: 1,
              high: 2,
              medium: 3,
              low: 4,
            };
            const sortedData = [...response?.data?.data].sort((a, b) => {
              const severityA = severityOrder[a.issueName?.Severity?.trim().toLowerCase()] || 5;
              const severityB = severityOrder[b.issueName?.Severity?.trim().toLowerCase()] || 5;
              return severityA - severityB;
            });
            setChartData(sortedData);
          } else {
            const data = response?.data?.data.filter((item) => item?.issueName?.Issue !== null)
            setChartData(data)
          }

          setShowLoader(false);
        })
        .catch((errDashboard) => {
          setShowLoader(false);
        });
    } catch (error) {
      setShowLoader(false);
    }
  };

  const colors = ['#2C226D', '#2C226D', '#2C226D', '#2C226D', '#2C226D'];
  const colors2 = ['#63A7E3', '#439B46', '#CA8B3B', '#BFA73E', '#7D4DD0']
  const colorsCritical = ['#2C226D', '#2C226D', '#2C226D', '#2C226D', '#2C226D'];
  const colorsCritical2 = ['#FF1B16', '#FFBC11', '#FF015E', '#DE22FF', '#FF5404']
  const i = 1;
  return (
    <>
      <Card sx={{ mb: 1 }}>
        <div className="top-heading-dashboard">
          <div className="filter-select-dropdown">
            <h2>Issue Vertical Bar Chart </h2>
            <FormControl className="width-set-formcontrol">
              <InputLabel id="demo-simple-select-label">Filter</InputLabel>
              <Select
                value={value}
                onChange={handleChange}
                // label="Filter"
                IconComponent={ArrowDropDownIcon}
              >
                <MenuItem value="false">Top Issues</MenuItem>
                <MenuItem value="true">Top Critical Issues</MenuItem>
              </Select>
              <FilterListIcon className="filter-icon" />
            </FormControl>
          </div>
        </div>
        <p className="top-issues-vertical-heading"> {value === "false" ? "Top Issues" : "Top Critical Issues"}</p>
        {!loader ?
          <>
            {(chartData && chartData?.length > 0) ?
              (chartData?.slice(0, 5)
                .map((key, i) => (
                  <Stack
                    sx={{ width: "100%", mt: 2 }}
                    spacing={2}
                    className="vertical-chart-linearprocess"
                  >

                    <div className="cover-vertical-chart">
                      <p className="linearprocess-peragraph">
                        {key?.issueName?.Issue}
                        {totalIssue && <span>{((Math.abs(Number(key?.issueCount)) * 100) / totalIssue)?.toFixed(2)}%</span>}
                      </p>
                      {value === "false" ? <LinearProgress
                        sx={{
                          backgroundColor: colors[i],
                          "& .MuiLinearProgress-bar": {
                            backgroundColor: colors2[i],
                          },
                        }}
                        variant="determinate"
                        className="vertical-progresschart"
                        value={key?.issueCount}
                      /> :
                        <LinearProgress
                          sx={{
                            backgroundColor: colorsCritical[i],
                            "& .MuiLinearProgress-bar": {
                              backgroundColor: colorsCritical2[i],
                            },
                          }}
                          variant="determinate"
                          className="vertical-progresschart"
                          value={key?.issueCount}
                        />
                      }
                    </div>
                  </Stack>
                ))) :
              // When no data is Available
              (
                <Stack
                  sx={{ width: "100%", mt: 2 }}
                  spacing={2}
                  className="vertical-chart-linearprocess"
                >

                  <div className="cover-vertical-chart">
                    <p className="linearprocess-peragraph">
                      No Data Available
                    </p>
                  </div>
                </Stack>
              )
            }
          </> :
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", marginTop: "23%" }}>
            <CircularProgress className="loader-custom-class-new" />
          </Box>
        }

      </Card>
    </>
  );
}

export default VerticalChartBarForIssue;
