import React from "react";
import {
  Box,
  Grid,
  Container,
  Tabs,
  Tab,
  Card,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import NewHeader from "../../../src/layouts/dashboard/header/NewHeader";
import "../../pages/css/newVendor.css";
import { useEffect, useState } from "react";
import OverviewHome from "./OverviewHome";
import RiskProfile from "./RiskProfile";
import Issues from "./Issues";
import PostRequest from "../../components/apiConnections/postRequest";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import { useSnackbar } from "notistack";
import { changeEcosystemTab } from "../../redux/activeTabSlice";
import moment from "moment";
import GetRequest from "../../components/apiConnections/getRequest";
import Cve from "./Cve";
import Compliance from "./Compliance";
import ClientRemediation from "./ClientRemediation";
import Loader from "../../Loader";
import AiAssessments from "./AiAssessments";
import FaildeControl from "./_failedControl";
import PassedControl from "./_passedControl";
import VendorDocuments from "./VendorDocuments";
import DocumentAnalysis from "../DocumentAnalysis";
import Breadcrumb from "./Breadcrumb";
import AILoader from "../../AILoader";
import { addVendorDetails } from "../../redux/vendorDetailsSlice";

function VendorDetailsPage() {
  const location = useLocation();
  const [mainTabValue, setMainTabValue] = useState(location.state?.mainTabValue || 0);
  const [nestedTabValue, setNestedTabValue] = useState(0);
  const [failedControlFilter, setFailedControlFilter] = useState();
  const handleMainTabChange = (event, newValue) => {
    setMainTabValue(newValue);
    setNestedTabValue(0); // Reset nested tab when main tab changes
    setFailedControlFilter();   // Reset failed control filter data when main tab changes
  };

  const handleNestedTabChange = (event, newValue) => {
    setNestedTabValue(newValue);
    setFailedControlFilter();   // Reset failed control filter data when main tab changes
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  let vendorData = useSelector((state) => state.vendorDetails.vendorDetails);
  const ecosystemActiveTab = useSelector((state => state.activeTab?.ecosystem));
  const data = useParams();
  const [domainName, setDomainName] = useState("-");
  const [vendorName, setVendorName] = useState("-");
  const [hosting, setHosting] = useState("--");
  const [address, setAddress] = useState("--");
  const [dns, setDns] = useState("");
  const [breaches, setBreaches] = useState("");
  const [encryption, setEncryption] = useState("");
  const [misconfiguration, setMisconfigration] = useState("");
  const [patching, setPatching] = useState("");
  const [totalScore, setTotalScore] = useState(0);
  const [dnsScore, setDnsScore] = useState("");
  const [breachesScore, setBreachesScore] = useState("");
  const [encryptionScore, setEncryptionScore] = useState("");
  const [misconfigrationScore, setMisconfigrationScore] = useState("");
  const [patchingScore, setPatchingScore] = useState("");
  const [dnsGrade, setDnsGrade] = useState("-");
  const [breachesGrade, setBreachesGrade] = useState("-");
  const [encryptionGrade, setEncryptionGrade] = useState("-");
  const [misconfigrationGrade, setMisconfigrationGrade] = useState("-");
  const [patchingGrade, setPatchingGrade] = useState("-");
  const [totalIssues, setTotalIssues] = useState();
  const [issuesListData, setIssuesListData] = useState({});
  const [patchingRowsData, setPatchingRowsData] = useState({});
  const [totalLow, setTotalLow] = useState(0);
  const [totalMedium, setTotalMedium] = useState(0);
  const [totalHigh, setTotalHigh] = useState(0);
  const [totalCritical, setTotalCritical] = useState(0);
  const [logo, setLogo] = useState("/assets/images/avatars/user.png");
  const { enqueueSnackbar } = useSnackbar();
  const [showLoader, setshowLoader] = useState(false);
  const [urlLinked, setUrlLinked] = useState('');
  const [reletionShipRisk, setReletionShipRisk] = useState('');
  const [overallGrade, setOverallGrade] = useState("-")
  const [vendorDetailsData, setVendorDetailsData] = useState();
  const [misconfigrationOldScore, setMisconfigrationOldScore] = useState(0);
  const [encryptionOldScore, setEncryptionOldScore] = useState(0);
  const [dnsOldScore, setDnsOldScore] = useState(0);
  const [breachesOldScore, setBreachesOldScore] = useState(0);
  const [patchingOldScore, setPatchingOldScore] = useState(0);
  // const [overallOldScore, setOverallOldScore] = useState(0);
  const [scores, setScores] = useState({});
  const [overallRiskLoader, setOverallRiskLoader] = useState(false);

  const [remediationData, setRemediationData] = useState({});
  const userEmail = window.localStorage.getItem("userEmail");
  const [vendorUrl, setVendorUrl] = useState(data.url);
  const [complianceCardData, setComplianceCardData] = useState([]);
  const [vendorType, setVendorType] = useState("");
  const [type, setType] = useState("");
  const [error, setError] = useState(false);
  const [mainLoader, setMainLoader] = useState(false);
  const [isPublicUrl, setIsPublicUrl] = useState("");
  const [value, setValue] = useState("1");
  const [documentValue, setDocumentValue] = useState("11");
  const [AIshowLoader, setshowAILoader] = useState(false);
  useEffect(() => {
    if (location.state?.mainTabValue !== undefined) {
      setMainTabValue(location.state.mainTabValue);
    }
    if (location.state?.nestedTabValue !== undefined) {
      setNestedTabValue(location.state.nestedTabValue);
    }
    if (location.state?.filterData !== undefined) {
      setFailedControlFilter(location.state?.filterData);
    }
    navigate(location.pathname, { replace: true });
  }, [location.state, navigate]);

  useEffect(() => {
    getVendorComplianceCard();
    getVendorOverAllRiskScore()
    if (ecosystemActiveTab === "4") {
      setValue("4")
      dispatch(changeEcosystemTab("1"))
    }
    if (ecosystemActiveTab === "5") {
      setValue("5")
      dispatch(changeEcosystemTab("1"))
    }
  }, []);

  const vendorDetailsAPICall = async () => {
    let resObj = { error: true, message: "unable to get subscriptions " }
    setMainLoader(true);
    try {
      const vendorPayload = {
        vendor: data.url,
      };
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REQUEST_SINGLE_VENDOR_DETAILS}`,
          vendorPayload
        )
          .then((res) => {
            if (res.status === 203) {
              // no data
              enqueueSnackbar("Requested Url not find", { variant: "error" });
              setError(true);
              setDomainName("");
              setMainLoader(false);
              resObj = { error: true, message: res.data?.message || res.message }
            }
            else {
              const response = res.data.data;
              setVendorDetailsData(res.data?.data);
              setMainLoader(false);
              resObj = { error: false, message: null, data: response }
            }
          })
          .catch((err) => {
            // no data
            enqueueSnackbar("Requested Url not find", { variant: "error" });
            setError(true);
            setMainLoader(false);
            resObj = { error: true, message: err.data?.message || err.message }
          });
      }
    } catch (error) {
      // no data
      enqueueSnackbar("Requested Url not find", { variant: "error" });
      setError(false);
      setMainLoader(false);
      resObj = { error: true, message: error.data?.message || error.message }
    }
    return resObj;
  };

  const populateVendorDetails = async (res) => {
    setMainLoader(true);
    try {
      let issuesRows = [];
      let patchingRows = [];
      var vendorTag = res?.vendorData?.vendorType;
      setVendorDetailsData(res);
      if (!vendorTag) {
        vendorTag = "Vendor";
      }
      setIsPublicUrl(res?.vendorData?.thirdPartyPublicUrl)
      setVendorType(vendorTag);
      setUrlLinked(res?.urlLink);
      setDomainName(res?.vendorData?.vendorInfo?.domain);
      setVendorName(res?.vendorDetail?.name);
      setReletionShipRisk(res?.vendorDetail?.relationshipRisk);
      setOverallGrade(res?.vendorData?.vendorInfo?.grade[0]?.Overall_score);
      // setRiskScore(
      //   res?.vendorData?.vendorInfo?.score[0]?.Overall_score
      // );
      setAddress(res?.vendorData?.vendorInfo?.ip);
      setHosting(res?.vendorData?.vendorInfo?.hosting);
      setLogo(
        res?.vendorData?.vendorInfo?.logo ||
        "/assets/images/avatars/user.png"
      );
      setDns(res?.vendorData?.vendorInfo?.count[0].Dns);
      setBreaches(
        res?.vendorData?.vendorInfo?.count[0]?.Breaches
      );
      setEncryption(
        res?.vendorData?.vendorInfo?.count[0]?.Encryption
      );
      setMisconfigration(
        res?.vendorData?.vendorInfo?.count[0]?.Misconfiguration
      );
      const vendorInfo = res?.vendorData?.vendorInfo;

      const dnsUrls = vendorInfo?.dns.flatMap(item => item.URL);
      const misconfigUrls = vendorInfo?.misconfiguration.flatMap(item => item.URL);
      setTotalIssues(dnsUrls.length + misconfigUrls.length);

      const dataMatch = [
        "breaches",
        "dns",
        // "encryption",
        "misconfiguration",
        "patching",
      ];
      let totalLow = 0;
      let totalMedium = 0;
      let totalHigh = 0;
      let totalCritical = 0;
      let patchingissues = 0;

      Object.entries(vendorInfo).map((key, valuevendorInfo) => {
        if (dataMatch.includes(key[0])) {
          const dataArr = key[1];
          dataArr.map((value) => {
            if (key[0] === "patching") {
              if (
                value.Issue === null ||
                value.Issue === "" ||
                value.Issue === "null"
              ) {
                totalLow = totalLow + 0;
                totalMedium = totalMedium + 0;
                totalHigh = totalHigh + 0;
                totalCritical = totalCritical + 0;
                patchingissues = patchingissues + 0;
              } else {
                const severity = String(value?.Severity)
                  .replace(/<[^>]*>/g, "")
                  .replaceAll('"', "")
                  .trim()
                  .toLowerCase();
                patchingRows.push(
                  {
                    // CVE, Technology, category, Description, CWE, view, Severity, Score, CPE, Reference, Remediation, URL
                    ...issuespatchingList(
                      !value.CVE ? "-" : value.CVE,
                      value.Technology || "",
                      key[0],
                      value.Description || "",
                      value.CWE || "",
                      "View",
                      severity,
                      value.Score,
                      value.CPE || "",
                      value.Reference || [],
                      value.Remediation || "",
                      value.URL || []
                    ),
                  }
                );
                patchingissues++;
                totalLow = severity === "low" ? totalLow + 1 : totalLow;
                totalMedium = severity === "medium" ? totalMedium + 1 : totalMedium;
                totalHigh = severity === "high" ? totalHigh + 1 : totalHigh;
                totalCritical = severity === "critical" ? totalCritical + 1 : totalCritical;
              }
            }
            else if (key[0] === "dns") {
              if (
                value.Issue === null ||
                value.Issue === "" ||
                value.Issue === "null"
              ) {
                totalLow = totalLow + 0;
                totalMedium = totalMedium + 0;
                totalHigh = totalHigh + 0;
                totalCritical = totalCritical + 0;
              } else {
                const severity = String(value?.Severity)
                  .replace(/<[^>]*>/g, "")
                  .replaceAll('"', "")
                  .trim()
                  .toLowerCase();
                totalLow = severity === "low" ? totalLow + 1 : totalLow;
                totalMedium = severity === "medium" ? totalMedium + 1 : totalMedium;
                totalHigh = severity === "high" ? totalHigh + 1 : totalHigh;
                totalCritical = severity === "critical" ? totalCritical + 1 : totalCritical;

                issuesRows.push(
                  issuesList(
                    !value.Issue ? "-" : value.Issue,
                    key[0],
                    String(value?.Severity)
                      .replace(/<[^>]*>/g, "")
                      .replaceAll('"', "")
                      .trim()
                      .toLowerCase(),
                    "View",
                    value,
                    value?.Reference,
                    value?.URL || []
                  )
                );
              }
            } else {
              if (
                value.Issue === null ||
                value.Issue === "" ||
                value.Issue === "null"
              ) {
                totalLow = totalLow + 0;
                totalMedium = totalMedium + 0;
                totalHigh = totalHigh + 0;
                totalCritical = totalCritical + 0;
              } else {
                const severity = String(value?.Severity)
                  .replace(/<[^>]*>/g, "")
                  .replaceAll('"', "")
                  .trim()
                  .toLowerCase();
                totalLow = severity === "low" ? totalLow + 1 : totalLow;
                totalMedium = severity === "medium" ? totalMedium + 1 : totalMedium;
                totalHigh = severity === "high" ? totalHigh + 1 : totalHigh;
                totalCritical = severity === "critical" ? totalCritical + 1 : totalCritical;

                issuesRows.push(
                  issuesList(
                    !value.Issue ? "-" : value.Issue,
                    key[0],
                    String(value?.Severity)
                      .replace(/<[^>]*>/g, "")
                      .replaceAll('"', "")
                      .trim()
                      .toLowerCase(),
                    "View",
                    value,
                    value?.Reference,
                    value?.URL || []
                  )
                );
              }
            }
          });
        }
        if (key[0] === "count") {
          setTotalScore(key[1][0].Overall_count);
        }
      });
      setPatchingRowsData(patchingRows);
      setIssuesListData(issuesRows);
      setTotalLow(totalLow);
      setTotalMedium(totalMedium);
      setTotalHigh(totalHigh);
      setTotalCritical(totalCritical);
      setPatching(res.vendorData?.vendorInfo?.count[0]?.Patching);

      // Score
      setDnsScore(res.vendorData?.vendorInfo?.score[0]?.Dns);
      setBreachesScore(res.vendorData?.vendorInfo?.score[0]?.Breaches);
      setEncryptionScore(res.vendorData?.vendorInfo?.score[0]?.Encryption);
      setMisconfigrationScore(res.vendorData?.vendorInfo?.score[0]?.Misconfiguration);
      setPatchingScore(res.vendorData?.vendorInfo?.score[0]?.Patching);

      // Set Old Scores 
      if (res.vendorData?.vendorInfo?.oldScore) {
        setDnsOldScore(res.vendorData?.vendorInfo?.oldScore[0]?.Dns);
        setBreachesOldScore(res.vendorData?.vendorInfo?.oldScore[0]?.Breaches);
        setEncryptionOldScore(res.vendorData?.vendorInfo?.oldScore[0]?.Encryption);
        setMisconfigrationOldScore(res.vendorData?.vendorInfo?.oldScore[0]?.Misconfiguration);
        setPatchingOldScore(res.vendorData?.vendorInfo?.oldScore[0]?.Patching);
        // setOverallOldScore(res.vendorData?.vendorInfo?.oldScore[0]?.Overall_score)
      }

      // Set Grades 
      if (res?.vendorData?.vendorInfo?.grade) {
        setDnsGrade(res?.vendorData?.vendorInfo?.grade[0]?.Dns);
        setBreachesGrade(res.vendorData?.vendorInfo?.grade[0]?.Breaches);
        setEncryptionGrade(res.vendorData?.vendorInfo?.grade[0]?.Encryption);
        setMisconfigrationGrade(res.vendorData?.vendorInfo?.grade[0]?.Misconfiguration);
        setPatchingGrade(res.vendorData?.vendorInfo?.grade[0]?.Patching);
      }
      setMainLoader(false);

    } catch (error) {
      // no data
      enqueueSnackbar("Requested Url not find", { variant: "error" });
      setError(false);
      setMainLoader(false);
      return false;
    }
  };

  const getVendorDetail = async () => {
    let vendors = await vendorDetailsAPICall();
    if (!vendors.error && vendors.data) {
      dispatch(addVendorDetails(vendors.data))
    }
  }
  useEffect(() => {
    if (!vendorData) {
      console.log("vendor Data Not present ")
      getVendorDetail();
    }
    else if ((vendorData?.vendorData?.vendorInfo?.domain && vendorData?.vendorData?.vendorInfo?.domain !== data.url)) {
      console.log("Vendor Domain  Changed  ", data.url)
      getVendorDetail();
    }
    else if (vendorData?.vendorData?.vendorInfo?.domain === data.url) {
      console.log("vendor Data present for ", vendorData?.vendorData?.vendorInfo?.domain);
      populateVendorDetails(vendorData);
    }
    console.log("vendorData", vendorData)
  }, [vendorData])

  const getVendorComplianceCard = async () => {
    setshowLoader(true);
    try {
      GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_VENDOR_COMPLIANCE_CARD}?domain=${vendorUrl}`
      )
        .then((res) => {
          const obj2 = res.data.data;
          let arr = [];
          for (let i = 0; i < Math.ceil(obj2.length / 3); i++) {
            let count = i * 3;
            arr.push(obj2.slice(count, count + 3));
          }
          setComplianceCardData(arr);
          setshowLoader(false);
        })
        .catch((err) => {
          // no data
          // enqueueSnackbar("Compliance card not find", { variant: "error" });
          setshowLoader(false);
          return false;
        });
    } catch (error) {
      // no data
      // enqueueSnackbar("Compliance card not find", { variant: "error" });
      setshowLoader(false);
      return false;
    }
  };

  function createData(category, issue, grade, score, oldScore) {
    return { category, issue, grade, score, oldScore };
  };

  const outsideInScoreTableRows = [
    createData("Breaches", breaches, breachesGrade, breachesScore, breachesOldScore),
    createData("DNS", dns, dnsGrade, dnsScore, dnsOldScore),
    createData("Encryption", encryption, encryptionGrade, encryptionScore, encryptionOldScore),
    createData("Misconfiguration", misconfiguration, misconfigrationGrade, misconfigrationScore, misconfigrationOldScore),
    createData("Patching", patching, patchingGrade, patchingScore, patchingOldScore),
  ];

  function issuesList(name, category, severity, view, ele, refs, urls) {
    return { name, category, severity, view, ele, refs, urls };
  };


  function issuespatchingList(CVE, Technology, Category, Description, CWE, view, Severity, Score, CPE, Reference, Remediation, URL) {
    return { CVE, Technology, Category, Description, CWE, view, Severity, Score, CPE, Reference, Remediation, URL };
  };

  const getReletionshipTag = (score) => {
    let riskTag;
    if (score && score <= 25) {
      riskTag = "Low";
    } else if (score && score <= 50) {
      riskTag = "Medium";
    } else if (score && score <= 75) {
      riskTag = "High";
    } else if (score && score > 75) {
      riskTag = "Critical";
    }
    return riskTag;
  }

  const breadcrumbs = [
    { label: 'Ecosystem', path: '/dashboard/vendors' },
    { label: `${vendorName}`, },
  ];

  const toggleLoader = (isLoading) => {
    setshowAILoader(isLoading);
  };

  const getVendorOverAllRiskScore = async () => {
    setOverallRiskLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_VENDOR_SCORE_DETAILS}?vendorDomain=${data?.url}`,
        )
          .then((res) => {
            let result = res?.data?.data;
            setScores(result);
            setOverallRiskLoader(false);
          })
          .catch((errChart) => {
            // enqueueSnackbar(errChart.message, { variant: "error" });
            setOverallRiskLoader(false);
          });
      } else {
        window.location.href = "/";
      }
    } catch (error) {
      // enqueueSnackbar(error.message, { variant: "error" });
      setOverallRiskLoader(false);
    }
  };

  const getGradeALfabate = (score) => {
    let GradeTag;
    if (score && score <= 25) {
      GradeTag = "D";
    } else if (score && score <= 50) {
      GradeTag = "C";
    } else if (score && score <= 75) {
      GradeTag = "B";
    } else if (score && score > 75) {
      GradeTag = "A";
    }
    return GradeTag;
  }
  return (
    <>
      <Helmet>
        <title>Genesis Platform</title>
      </Helmet>
      <Loader show={mainLoader || showLoader} />
      {AIshowLoader ? (
        <AILoader show={AIshowLoader} />
      ) : null}
      <Container maxWidth={false} className="max-w-left">
        <NewHeader titleName="Security Profile" />
        <Grid container spacing={3} className="ipad-space">
          {domainName !== "" && domainName && (
            <>
              <Grid item xs={12} md={12} lg={12}>
                <div className="security-breadcum">
                  <div>
                    <Breadcrumb crumbs={breadcrumbs} />
                    <h3>Vendor Info</h3>
                  </div>
                  {/* <Button variant="contained" className="genesis-ai-btn">
                Genesis AI
              </Button> */}
                  <DocumentAnalysis domain={vendorUrl}></DocumentAnalysis>
                </div>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <div className="infor-vendor-detail-box">
                  <div className="domain-detail-platte">
                    <div className="logo-sign">
                      <img src={logo} alt="icon" />
                    </div>
                    <div className="line-right">{vendorName}</div>
                    <div className="line-right">{domainName}</div>
                    <div className="line-right">{address}</div>
                    <div className="line-right">
                      <p>Grade: </p>
                      <span className={`gradClass ${getGradeALfabate(scores?.overallRiskScore)}`}>
                        {getGradeALfabate(scores?.overallRiskScore)}
                      </span>
                    </div>
                    <div className="line-right right-0">
                      {reletionShipRisk ? (
                        <span className={`tagClass ${getReletionshipTag(reletionShipRisk)}`}>
                          {getReletionshipTag(reletionShipRisk)}
                        </span>
                      ) : (
                        "-"
                      )}
                    </div>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <Tabs
                  value={mainTabValue}
                  onChange={handleMainTabChange}
                  className="tab-main-heading margin-mui-tabfix"
                  aria-label="basic tabs example"
                >
                  <Tab className="active-color-remove" label="Overview" />
                  <Tab className="active-color-remove" label="Findings" />
                  <Tab className="active-color-remove" label="Compliance" />
                  <Tab className="active-color-remove" label="Remediation" />
                  <Tab className="active-color-remove" label="Controls" />
                  <Tab className="active-color-remove" label="AI Assessments" />
                  <Tab className="active-color-remove" label="Documents" />
                </Tabs>
                <Box>
                  {mainTabValue === 0 && (
                    <div className="first_tab">
                      <Tabs
                        value={nestedTabValue}
                        onChange={handleNestedTabChange}
                        className="tab-main-heading margin-mui-tabfix"
                        aria-label="nested tabs example"
                      >
                        <Tab
                          className="active-color-remove second-mui"
                          label="Home"
                        />
                        <Tab
                          className="active-color-remove second-mui"
                          label="Risk Profile"
                        />
                      </Tabs>
                      <Box>
                        {nestedTabValue === 0 && !showLoader && !mainLoader && <OverviewHome data={vendorDetailsData}
                          // sumScore={sumScore}
                          totalLow={totalLow}
                          totalCritical={totalCritical}
                          totalMedium={totalMedium}
                          totalHigh={totalHigh}
                          complianceCardData={complianceCardData}
                          outsideInScoreTableRows={outsideInScoreTableRows}
                          totalScore={totalScore}
                          score={scores}
                          loader={overallRiskLoader}
                          getVendorOverAllRiskScore={() => getVendorOverAllRiskScore()}
                        />}

                        {nestedTabValue === 1 && (
                          <div>
                            <RiskProfile domainName={domainName} />
                          </div>
                        )}
                      </Box>
                    </div>
                  )}
                  {mainTabValue === 1 && (
                    <div className="issues-main-bg first_tab">
                      <div className="tab-issues-flex">
                        <div className="total-issue-three">
                          <h3>Total Issues</h3>
                          {/* <p>{issuesListData && issuesListData?.length || 0} </p> */}
                          <p>{totalIssues || 0} </p>
                        </div>
                        <div className="total-issue-three">
                          <h3>Total CVE</h3>
                          <p>{patchingRowsData && patchingRowsData?.length || 0} </p>
                        </div>
                        <div className="total-issue-three">
                          <h3>Pending Remediation</h3>
                          <p>{vendorDetailsData?.pendingRemediationCount || 0}</p>
                        </div>
                      </div>
                      <Tabs
                        value={nestedTabValue}
                        onChange={handleNestedTabChange}
                        className="tab-main-heading"
                        aria-label="issues nested tabs example"
                      >
                        <Tab
                          className="active-color-remove second-mui nextmi-selected"
                          label="Issue"
                        />
                        <Tab
                          className="active-color-remove second-mui nextmi-selected"
                          label="CVE"
                        />
                      </Tabs>
                      <Box>
                        {nestedTabValue === 0 && (
                          <div>
                            <Issues
                              issuesListData={issuesListData}
                            />
                          </div>
                        )}
                        {nestedTabValue === 1 &&
                          <div>
                            <Cve
                              patchingRowsData={patchingRowsData}
                            />
                          </div>}
                      </Box>
                    </div>
                  )}
                  {mainTabValue === 2 &&
                    <div>
                      <Box>
                        <Compliance />
                      </Box>
                    </div>
                  }
                  {mainTabValue === 3 && <div>
                    {/* Remediation Content */}
                    <ClientRemediation remediationListData={remediationData} />
                  </div>}
                  {mainTabValue === 4 && (
                    <div className="first_tab">
                      <Tabs
                        value={nestedTabValue}
                        onChange={handleNestedTabChange}
                        className="tab-main-heading"
                        aria-label="nested tabs example"
                      >
                        <Tab
                          className="active-color-remove second-mui"
                          label="Passed"
                        />
                        <Tab
                          className="active-color-remove second-mui"
                          label="Failed"
                        />
                      </Tabs>
                      <Box>
                        {nestedTabValue === 0 && <div>
                          <PassedControl />
                        </div>}
                        {nestedTabValue === 1 && (
                          <div>
                            <FaildeControl setLoader={toggleLoader} filter={failedControlFilter} />
                          </div>
                        )}
                      </Box>
                    </div>
                  )}
                  {mainTabValue === 5 && <div>
                    <AiAssessments
                      domainName={domainName}
                    />
                  </div>}
                  {mainTabValue === 6 && <div><VendorDocuments /> </div>}
                </Box>
              </Grid>
            </>)}
          {error && domainName === "" && (
            <Box>
              <Grid item xs={12} md={12} lg={12}>
                <div className="security-breadcum">
                  <div>
                    <Breadcrumb crumbs={breadcrumbs} />
                    <h3>Vendor Info</h3>
                  </div>
                </div>
              </Grid>
              <Grid>
                <Card item xs={12} md={6} lg={6} sx={{ p: 5 }}>
                  <h4>No Record for this url</h4>
                </Card>
              </Grid>
            </Box>
          )}
        </Grid>
      </Container>
    </>
  );
}

export default VendorDetailsPage;
