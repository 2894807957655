import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Grid,
  Link,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import UserProfile from "./../../assets/user-profile.svg";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { LoadingButton } from "@mui/lab";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import AddNewVendorByClient from "../AddNewVendorByClient";
import ModalDialog from "../ModalDialog";
import Plus from '../../assets/plus-vector.svg';

export function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AddVendor = ({ props, stepper }) => {
  const addClientDetails = props.addClientDetails;
  const changeActiveComponent = props.changeActiveComponent;
  const submitData = props.submitData;
  const [formData, setFormData] = useState({
    ...props.client,
    vendorDomain: props.client?.vendorDomain || [],
    vendorDetails: {},
    selectedTab: 0
  });
  const [dataSaved, setDataSaved] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [csvData, setCsvData] = useState({});
  const [showAddVendor, setShowAddVendor] = useState(false)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [vendorStatus, setVendorStatus] = useState("Onboarding")
  const [withoutVendor, setWithoutVendor] = useState(false)
  // useEffect(() => {
  //   const tab =
  //     formData?.vendorDomainName && formData?.vendorDomain?.length > 1 ? 1 : 0;
  //   setFormData((prev) => ({
  //     ...prev,
  //     selectedTab: tab,
  //   }));
  // }, [formData.vendorDomain]);

  const handleTabChange = (event, newValue) => {
    setDataSaved(false);
    setFormData((prev) => ({
      ...prev,
      vendorDomainName: "",
      vendorDomain: [],
      selectedTab: newValue,
      filename: "",
    }));
  };

  const handleInputChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      vendorDomainName: e.target.value,
    }));
  };

  const handleFileUpload = (event) => {
    const files = event.target.files;
    if (files.length === 0) {
      return;
    }

    const file = files[0];
    const fileName = file.name;
    const fileExtension = fileName.split(".").pop().toLowerCase();

    if (fileExtension !== "csv" && fileExtension !== "xlsx") {
      enqueueSnackbar("Only CSV and XLSX files are supported.", {
        variant: "warning",
      });
      return;
    }
    setFormData((prev) => ({
      ...prev,
      filename: fileName,
    }));
    if (fileExtension === "csv") {
      handleCSVFile(file, fileName);
    } else if (fileExtension === "xlsx") {
      handleXLSXFile(file);
    }
  };

  const handleCSVFile = (file, fileName) => {
    Papa.parse(file, {
      download: true,
      header: false,
      dynamicTyping: true,
      skipEmptyLines: true,
      complete: (results) => {
        console.log("results", results)
        const validRows = results.data.filter((row, i) => i !== 0);
        const flattenedRows = validRows.map((row) => ({ domain: row[0], vendorStatus: row[1] }))?.filter(item => item.domain && item.vendorStatus);
        console.log(flattenedRows, "flatted")
        if (!flattenedRows.length) {
          enqueueSnackbar(`Invalid file`, {
            variant: "error",
          });
          return;
        }
        let invalid = false;
        if (flattenedRows && Array.isArray(flattenedRows)) {
          for (let [index, item] of Object.entries(flattenedRows)) {
            if (!isValidDomain(item.domain)) {
              enqueueSnackbar(`Invalid domain at row: ${Number(index) + 2}`, {
                variant: "error",
              });
              invalid = true;
              break;
            } else if (!["New", "Existing"].includes(item.vendorStatus)) {
              enqueueSnackbar(`Status should be New/Existing at row: ${Number(index) + 2}`, {
                variant: "error",
              });
              invalid = true;
              break;
            }
          }
        }
        if (invalid) {
          setCsvData({ ...csvData, vendorDomain: [], filename: "" })
        } else {
          setCsvData((prev) => ({
            ...prev,
            vendorDomain: flattenedRows,
            filename: fileName,
          }));
        }
      },
    });
  };

  const handleXLSXFile = (file, fileName) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const rows = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      const validRows = rows.filter((row, i) => row.length === 2 && i !== 0);
      const flattenedRows = validRows.map((row) => ({ domain: row[0], vendorStatus: row[1] }));
      if (!flattenedRows.length) {
        enqueueSnackbar(`Invalid file`, {
          variant: "error",
        });
        return;
      }
      let invalid = false;
      if (flattenedRows && Array.isArray(flattenedRows)) {
        for (let [index, item] of Object.entries(flattenedRows)) {
          if (!isValidDomain(item.domain)) {
            enqueueSnackbar(`Invalid domain at row: ${Number(index) + 2}`, {
              variant: "error",
            });
            invalid = true;
            break;
          } else if (!["New", "Existing"].includes(item.vendorStatus)) {
            enqueueSnackbar(`Status should be New/Existing at row: ${Number(index) + 2}`, {
              variant: "error",
            });
            invalid = true;
            break;
          }
        }
      }
      if (invalid) {
        setCsvData({ ...csvData, vendorDomain: [], filename: "" })
      } else {
        setCsvData((prev) => ({
          ...prev,
          vendorDomain: flattenedRows,
          filename: fileName,
        }));
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const isValidDomain = (domain) => {
    const domainRegex =
      /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/;
    return domainRegex.test(domain);
  };

  const checkValidation = () => {
    // if (formData?.vendorDomain?.length === 0) {
    //   enqueueSnackbar("Vendor Domain is required.", { variant: "warning" });
    //   setDataSaved(false);
    //   return false;
    // } else if (formData.vendorDomain.length > formData.numberOfVendors) {
    //   enqueueSnackbar(
    //     `Only ${formData.numberOfVendors} Vendors can be added.`,
    //     { variant: "warning" }
    //   );
    //   return false;
    // }
    return true;
  };

  const onSubmit = (e) => {
    if (checkValidation()) {
      addClientDetails(formData);
      console.log("vendorstatus", vendorStatus)
      if (formData.selectedTab == 1 && !withoutVendor) submitData({ ...formData, bulkVendorList: csvData }, "Bulk");
      else submitData({ ...formData, vendorStatus: vendorStatus });
      setFormData({
        selectedTab: 0,
      });
    }
  };

  const addVendorDomain = () => {
    const trimmedDomainName = formData?.vendorDomainName?.trim();
    if (trimmedDomainName !== "") {
      setFormData((prev) => ({
        ...prev,
        vendorDomain: [...new Set([...prev.vendorDomain, trimmedDomainName])],
      }));
    } else {
      enqueueSnackbar("Invalid input: Domain name cannot be empty.", {
        variant: "warning",
      });
    }
  };

  const onSave = () => {
    setDataSaved(true);
    if (
      formData?.selectedTab === 0 &&
      formData?.vendorDomainName.trim() !== ""
    ) {
      addVendorDomain();
    } else if (formData?.selectedTab === 1 && csvData.length > 0) {
      setFormData((prev) => ({
        ...prev,
        vendorDomain: csvData,
      }));
    }
  };

  const handleNext = () => {
    if (formData.selectedTab == 1) {
      // handleBulkSubmit(formData.vendorDomain)
      setOpenConfirmModal(true)
    } else {
      if (!new RegExp("([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?").test(formData?.vendorDomainName)) {
        enqueueSnackbar('Please enter correct URL.', { variant: 'error' });
        return;
      }
      setShowAddVendor(true)
    }
  }

  const handleSuccess = (vendorData) => {
    console.log(vendorData, "vendorData")
    setFormData({ ...formData, vendorDetails: vendorData })
    setOpenConfirmModal(true)
  }
  const ConfirmModalContent = () => {
    return (
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        {/* <img src={Plus} alt="icon" /> */}
        <h3 className="send-assessments">
          Please confirm, you want to add client with provided details ?
        </h3>
      </Typography>
    );
  }
  return (
    <>
      <Grid container spacing={0.5} sx={{ mt: 2 }}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <div className="d-flex-company-info">
            <div className="d-flex-company-logo">
              <img
                src={
                  typeof formData.logo === "string"
                    ? `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_STATIC_PATH}${formData?.logo}`
                    : formData.logo
                      ? URL.createObjectURL(formData.logo)
                      : UserProfile
                }
                alt="login"
                style={{
                  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.05)",
                }}
              />
            </div>
            <div className="align-peragraph-p">
              <h3>{formData.company}</h3>
              <p>
                <PermIdentityIcon />{" "}
                {`${formData.firstName} ${formData.lastName}`}
              </p>
            </div>
          </div>
        </Grid>
      </Grid>

      <Box className="mbtop-manage" sx={{ mt: 4 }}>
        <Typography variant="h4">
          <span className="heading-main">Add Vendors</span>
        </Typography>
      </Box>
      <div className="subscription-form">
        <Card sx={{ mt: 1, mb: 5, width: "600px" }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            {!formData?.clientId && stepper}
          </Stack>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <Box className="add-vendor-tabs">
                <Tabs
                  value={formData?.selectedTab}
                  onChange={handleTabChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Individual" {...a11yProps(0)} />
                  <Tab label="Bulk" {...a11yProps(1)} />
                </Tabs>
              </Box>
            </Grid>
            <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
              <LoadingButton
                size="medium"
                variant="contained"
                color="success"
                className="upload-new rightside"
                onClick={() => {
                  addClientDetails(formData);
                  changeActiveComponent(2);
                }}
                sx={{ float: "right" }}
              >
                Back
              </LoadingButton>
            </Box>
            <Grid item xs={12} md={12} lg={12}>

              <CustomTabPanel value={formData?.selectedTab} index={0}>
                {!showAddVendor ? <>
                  <div>
                    <h4 className="entervalid-domain-text">
                      Please Enter the valid domain of an individual vendor to
                      connect with the Client. If you want to connect with
                      multiple vendors, click on "Bulk" to upload a CSV file.
                    </h4>
                  </div>
                  <Grid container spacing={2} sx={{ marginBottom: "40px" }}>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        size="large"
                        placeholder="Enter Domain Here..."
                        name="vendorDomainName"
                        value={formData?.vendorDomainName}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <div className='flex-uploadbtn-flex' >
                        <div className='radio-type-design' onChange={(e) => setVendorStatus(e.target.value)}>
                          <input type="radio" id="test1" value="Onboarding" name="radio-group" checked={vendorStatus === "Onboarding"} />
                          <label for="test1">New Vendor</label>
                        </div>
                        <div className='radio-type-design' onChange={(e) => setVendorStatus(e.target.value)}>
                          <input type="radio" id="test2" value="Monitoring" name="radio-group" checked={vendorStatus === "Monitoring"} />
                          <label for="test2">Existing Vendor</label>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </> : null}
                {showAddVendor ? <AddNewVendorByClient
                  domain={formData?.vendorDomainName}
                  vendorStatus={null}
                  jsonAvailableData={false}
                  handleSuccess={handleSuccess}
                  handleClose={() => setShowAddVendor(false)}
                /> : null}
              </CustomTabPanel>

              <CustomTabPanel value={formData?.selectedTab} index={1}>
                <Box
                  // onDrop={handleDrop}
                  // onDragOver={handleDragOver}
                  sx={{
                    border: "2px dashed #ccc",
                    borderRadius: "8px",
                    padding: "20px",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  <CloudUploadIcon fontSize="large" />
                  <Typography variant="h6">
                    Please choose a CSV file containing the list of
                    domains
                  </Typography>
                  <input
                    type="file"
                    accept=".csv, .xlsx"
                    onChange={handleFileUpload}
                    style={{ display: "none" }}
                    id="file-upload"
                  />
                  <label htmlFor="file-upload">
                    <Button variant="contained" component="span" sx={{ mt: 2 }}>
                      Choose File
                    </Button>
                  </label>
                  {formData?.filename && (
                    <Typography variant="body2" sx={{ mt: 2 }}>
                      {formData?.filename}
                    </Typography>
                  )}
                  <br />
                  <br />
                  <Link
                    href={`${process.env.REACT_APP_BACKEND_BASE_URL}/assets/demo-domains.xlsx`}
                    download="demo-domains.xlsx"
                    underline="none"
                  >
                    Download demo file
                  </Link>
                </Box>
              </CustomTabPanel>
            </Grid>
          </Grid>
          <div className="d-flex-next-btn">
            {!dataSaved && !showAddVendor ? (
              <LoadingButton
                size="medium"
                variant="contained"
                color="success"
                className="without-vendor "
                // onClick={onSave}
                onClick={() => { setOpenConfirmModal(true); setWithoutVendor(true) }}
                sx={{ marginTop: "20px", marginLeft: "20px" }}
              >
                Continue without vendor
              </LoadingButton>
            ) : null}
          </div>
          <div className="d-flex-next-btn">
            {!dataSaved && !showAddVendor ? (
              <LoadingButton
                size="medium"
                variant="contained"
                color="success"
                className="upload-new rightside"
                // onClick={onSave}
                onClick={handleNext}
              >
                Next
              </LoadingButton>
            ) : null}
          </div>
        </Card >
      </div >
      <ModalDialog
        handleClose={() => { setOpenConfirmModal(false); setWithoutVendor(false) }}
        open={openConfirmModal}
        onSubmit={onSubmit}
        contentData={ConfirmModalContent}
      />
    </>
  );
};

export default AddVendor;
